//import Lottie from "react-lottie-player";
import { useState, useEffect } from "react";
import _ from "lodash";
import useStore from "../store/tokens";
import useTracker from "../store/tracker";
import Spacer from "../components/Spacer";
import WeeklyGoals from "../components/WeeklyGoals";
import SuccessAnimation from "../components/SuccessAnimation";
import ErrorAnimation from "../components/ErrorAnimation";
import YoutubeEmbed from "../components/Youtube";
import { Circle } from "rc-progress";
import qa from "../data/questions.json";
import medias from '../data/medias.json'

export default function App() {
  const tracker = useTracker((state) => state.tracker);
  const addToTracker = useTracker((state) => state.addToTracker);
  const [questions, setQuestions] = useState(qa);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);
  const [showErrorAnimation, setShowErrorAnimation] = useState(false);
  const increaseTokens = useStore((state) => state.increaseTokens);

  useEffect(() => {
    if (!!showSuccessAnimation) {
      setTimeout(() => setShowSuccessAnimation(false), 2000);
    }
  }, [showSuccessAnimation]);

  useEffect(() => {
    if (!!showErrorAnimation) {
      setTimeout(() => setShowErrorAnimation(false), 2000);
    }
  }, [showErrorAnimation]);

  function handleGoodAnswer() {
    if (currentIndex + 1 <= questions.length) {
      setCurrentIndex(currentIndex + 1);
      
      const acquired = tracker.acquired
      ? _.uniq([...tracker.acquired, ...questions[currentIndex].meta])
        : questions[currentIndex].meta
      
      const notAcquired = tracker.notAcquired
        ? _.filter(tracker.notAcquired, function (o) {
          console.log('aquired', acquired)
          console.log('O', o)
          return acquired.indexOf(o) === -1
        })
      : []
      
      addToTracker({
        goodAnswers: tracker.goodAnswers ? tracker.goodAnswers + 1 : 1,
        acquired,
        notAcquired
      });
      setShowSuccessAnimation(true);
      increaseTokens();
    }
  }

  function handleWrongAnswer() {
    if (currentIndex + 1 <= questions.length) {
      setCurrentIndex(currentIndex + 1);
      setQuestions([...questions, questions[currentIndex]]);

      const notAcquired = tracker.notAcquired
      ? _.uniq([...tracker.notAcquired, ...questions[currentIndex].meta])
        : questions[currentIndex].meta
      
      const acquired = tracker.acquired
      ? _.filter(tracker.acquired, function (o) {
        console.log('notAcquired', notAcquired)
        console.log('O', o)
        return notAcquired.indexOf(o) === -1
      })
    : []

      setShowErrorAnimation(true);
      addToTracker({
        badAnswers: tracker.badAnswers ? tracker.badAnswers + 1 : 1,
        notAcquired,
        acquired
      });

    }
  }

  function handleAnswer(answerIndex, expectedAnswerIndex) {
    findMedia()

    if (answerIndex === expectedAnswerIndex) {
      handleGoodAnswer();
    } else {
      handleWrongAnswer();
    }
  }

  function findMedia() {
    const notAcquired = tracker.notAcquired || []
    if (!notAcquired.length) {
        addToTracker({
          media: null,
        });
    }
    const intersec = medias.filter(m => _.intersection(notAcquired, m.meta).length >= 2) 
    if (intersec && intersec[0] && intersec[0].media) {
      addToTracker({
        media: intersec[0].media,
      });
    }
  }

  return (
    <div className="container">
      <Spacer size={32} />
      <div className="App-grid">
        <div className="Card" style={{ position: "relative" }}>
          <h3>Reputation Management</h3>
          <div
            style={{
              position: "absolute",
              width: "20px",
              right: "20px",
              top: "15px",
            }}
          >
            <Circle percent={75} strokeWidth={10} strokeColor="orange" />
          </div>

          <Spacer size={16} />
          {showErrorAnimation && (
            <div className="modal">
              <ErrorAnimation />
            </div>
          )}
          {showSuccessAnimation && (
            <div className="modal">
              <SuccessAnimation />
            </div>
          )}

          {questions.length === currentIndex ? (
            <>Next chapter...</>
          ) : (
            <UneQuestion
              handleAnswer={handleAnswer}
              questions={questions}
              currentIndex={currentIndex}
            />
          )}
        </div>
        <div>
          {
            tracker.media && ( <div>
           
             <div className="completed-list">
                <YoutubeEmbed embedId={tracker.media.embedId } />
              </div>
              
           </div>)
          }
          <Spacer size={16} />
          <div className="Card" style={{ backgroundColor: "#1C1D1F" }}>
            <h3 style={{ color: "white" }}>Completed</h3>
            <div className="completed-list">
              <PastChapters />
            </div>
          </div>
          <Spacer size={16} />
          <WeeklyGoals />
          <Spacer size={16} />

          <Spacer size={16} />
        </div>
      </div>
      <Spacer size={56} />
    </div>
  );
}

const PastChapters = () => {
  return (
    <ul>
      <li>Market Segmentation</li>
      <li>Pricing Strategies</li>
      <li>Available Tools</li>
      <li>Calculation of indicators and matricies</li>
      <li>Restrictions</li>
    </ul>
  );
};

const UneQuestion = ({ questions, currentIndex, handleAnswer }) => {
  const { question, answer, answers } = questions[currentIndex];
  return (
    <div>
      <h1>{question}</h1>
      <>
        {answers.map((a, k) => (
          <button key={a} onClick={() => handleAnswer(answer, k)}>
            {a}
          </button>
        ))}
      </>
    </div>
  );
};
