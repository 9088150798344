import { FiUser } from "react-icons/fi";
import { Link } from "react-router-dom";
export default function Navbar({tokens}) {
  return (
    <div className="App-profile">
     
      🥇<Link to="giftcards"> <span style={{ borderBottom: '1px dashed rgba(255,255,255,.2)' }}>{ tokens } Tokens</span></Link>
      <a href="#profile"><FiUser /> Yahya</a>
     
    </div>
  );
}
