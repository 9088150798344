import { NavLink } from "react-router-dom";
import { FiHome, FiHexagon, FiSettings } from "react-icons/fi";
import Profile from "./Profile";
import useStore from "../store/tokens";

export default function Navbar() {
  const tokens = useStore((state) => state.tokens);
  return (
    <nav className="App-nav">
      <div className="container">
        <NavLink
         exact="true"
          to="/"
         
        >
          <FiHome />
          Home
        </NavLink>
        <NavLink
          exact="true"
          to="/courses"
         
        >
          <FiHexagon />
          Courses
        </NavLink>
        <NavLink
          exact="true"
          to="/settings"
         
        >
          <FiSettings />
          Settings
        </NavLink>
      </div>
      <Profile tokens={ tokens } />
    </nav>
  );
}
