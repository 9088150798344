import Lottie from "react-lottie-player";
import success from '../assets/error.json'

export default function Example() {
    return (
      <Lottie
        loop={false}
        animationData={success}
        play
        style={{ width: 150, height: 150 }}
      />
    )
  }