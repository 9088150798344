import "./App.scss";
import { Link } from "react-router-dom";
//import Lottie from "react-lottie-player";
import Spacer from "./components/Spacer";
import WeeklyGoals from './components/WeeklyGoals'
//import marcheAnimation from "./assets/marche.json";
import metiers from "./data/metiers.json";
import { Line } from 'rc-progress';

export default function App() {
  return (
    <div className="container">
      <Spacer size={32} />
      <div className="App-grid">

        <div className="Card">
          
          <h2>Let's keep learning, Yahya</h2>
          <Spacer size={16} />
          <Course {...metiers[0]} line/>
          <Spacer size={32} />
          <button>Show all</button>
          <Spacer size={32} />
          <h2>Highlight of the week</h2>
       
          <Course {...metiers[1]} />
          <Spacer size={16} />
          <Course {...metiers[2]} />
 
        </div>
 <WeeklyGoals />
        
      </div>
      <Spacer size={56} />
   
    </div>
  );
}


const Course = ({title, image, description, line}) => {

  return (
    <> 
       <Link className="course-card" to='course'>
      <img src={image} width='100%' height={'150px'} alt={title} />
      <Spacer size={16} />
      <div>

      <h4>{title} {line && <Line percent={75} strokeWidth={1.1} strokeColor="#ff5722" />}</h4>
      <p style={{color:"rgba(0,0,0,.5)"}}>{description}</p>
      </div>
      

    </Link>
   
    </>
   
   
  )
}