import useStore from "../store/tracker";

export default function DebugPanel() {
  const tracker = useStore((state) => state.tracker);

  return (
    <div className="debug-panel">
      <div className="debug-header">Console</div>
          <div className="debug-content">
             
        {tracker.goodAnswers && (
          <div>
                      📊 Success rate:{" "}
                      {
                  !tracker.badAnswers && tracker.goodAnswers  && "100"
              }
            {tracker.badAnswers && ((tracker.goodAnswers / (tracker.goodAnswers + tracker.badAnswers)) * 100).toFixed(2)}%
          </div>
        )}

        <div>
          {" "}
          ✔️Passing:
          {tracker?.acquired &&
            tracker.acquired.map((t) => (
              <div className={"button-79"} key={t}>
                {t}
              </div>
            ))}
        </div>
        <div>
          {" "}
          ❌ Failing:
          {tracker?.notAcquired &&
            tracker.notAcquired.map((t) => (
              <div
                className={"button-79"}
                key={t}
                style={{ background: "#f44336" }}
              >
                {t}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
