import Spacer from "./Spacer";
import useStore from "../store/tokens";
export default function WeeklyGoals() {
  const tokens = useStore((state) => state.tokens);
  return (
    <div>
      <div className="Card" style={{ backgroundColor: "#1C1D1F" }}>
        <h3 style={{ color: "white" }}>Weekly Goals</h3>
        <p style={{ color: "white" }}>
          Yahya, give me 20 minutes and I'll give you my 100%. You're almost
          there champ!
        </p>
        <h1 style={{ color: "#ffeb3b", textAlign: "center", fontSize: "80px" }}>
          🔥 {Math.round(tokens*100/40000)}%
        </h1>

        <a href="#linkedin">Share</a>
      </div>
      <Spacer size={32} />
    </div>
  );
}
