import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./Home";
import Courses from "./routes/Courses";
import Course from "./routes/Course";
import Settings from "./routes/Settings";
import GiftCards from './routes/GiftCards'
import DebugPanel from "./components/DebugPanel";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Navbar />
    <DebugPanel />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="courses" element={<Courses />} />
      <Route path="course" element={<Course />} />
      <Route path="settings" element={<Settings />} />
      <Route path="giftcards" element={<GiftCards />} />
    </Routes>
  </BrowserRouter>
);
